import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { theme } from "../../modules/Theme";

import { createStoreReview } from "../../Actions";
import { fetchDashboardData } from "../../views/products/ProductsActions";
import { getSettings } from "../../Selectors";
import {
  getDashboardData,
  getIsFetchingDashboard,
  getProducts,
} from "../../views/products/ProductsSelectors";
import { get, isEmpty } from "lodash";

import DashboardCard, {
  PrebuiltCard,
  ProductsCard,
  ReviewCard,
  SuppliersCard,
} from "./components/DashboardCard";

import Cookies from "js-cookie";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetchingDashboard(state),
  products: getProducts(state),
  dashboardData: getDashboardData(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchDashboardData, createStoreReview }, dispatch),
});

const PageWrapper = styled.div`
  background: ${theme.colors.lightGrey};
  padding: 20px;
  @media (max-width: 800px) {
    padding: 10px;
  }
`;

const TopCategoriesLink = styled.div`
  font-size: 18px;
  color: ${theme.colors.main};
  font-weight: 400;
  margin-bottom: 20px;
  display: inline-block;
  width: 260px;
  cursor: pointer;
`;

class Dashboard extends Component {
  state = { showTutorialVideos: true, prebuiltPopupDismissed: false };

  componentDidMount() {
    this.props.fetchDashboardData();
  }

  render() {
    const showAllProducts = get(this.props, ["show_all_products"])
    const featuredSuppliers = get(this.props, ["dashboardData", "featured_suppliers"]);
    const newSuppliers = get(this.props, ["dashboardData", "new_suppliers"]);
    const categories = get(this.props, ["dashboardData", "categories"]);
    const products = get(this.props, ["products"]);
    const favouriteSuppliers = get(this.props, ["dashboardData", "favourite_suppliers"]);
    const storeSettings = get(this.props, ["settings", "store"]);
    const importCount = get(storeSettings, "imported_to_shopify_count");
    const appStoreReview = get(storeSettings, "app_store_review");
    const hidePrebuilt = get(storeSettings, "hide_prebuilt");
    const storeID = get(storeSettings, ["id"]);
    const isShopify =
      get(this.props, ["settings", "store", "platform", "name"]) === "SHOPIFY";
    const storePlatform = get(this.props, [
      "settings",
      "store",
      "platform",
      "name",
    ]);

    return (
      <PageWrapper>
        <TopCategoriesLink onClick={() => this.props.history.push("/setup")}>
          Customize Top Categories
        </TopCategoriesLink>
        {importCount > 0 &&
          !(appStoreReview && appStoreReview.rating === 5) &&
          !Cookies.get("reviewSaved") && (
            <ReviewCard
              platform={storePlatform}
              createStoreReview={this.props.createStoreReview}
            />
          )}
        {!isEmpty(storeSettings) &&
          !hidePrebuilt &&
          isShopify &&
          !Cookies.get("dismissPrebuilt") &&
          !this.state.dismissedPrebuilt && (
            <DashboardCard
              title="$249.00 Shopify Starter Stores"
              hide={() => {
                Cookies.set("dismissPrebuilt", true, { expires: 30 });
                this.setState({ dismissedPrebuilt: true });
              }}
            >
              <PrebuiltCard
                onClick={() =>
                  this.props.history.push("/prebuilt?type=general_store")
                }
                background="https://res.cloudinary.com/dropcommerce/image/upload/v1602818108/Prebuilt%20Cards/general-store-card_dayj4p.jpg"
              />
              <PrebuiltCard
                onClick={() => this.props.history.push("/prebuilt?type=pets")}
                background="https://res.cloudinary.com/dropcommerce/image/upload/v1602818108/Prebuilt%20Cards/pets-card_cs4xzv.jpg"
              />
              <PrebuiltCard
                onClick={() =>
                  this.props.history.push("/prebuilt?type=home_decor")
                }
                background="https://res.cloudinary.com/dropcommerce/image/upload/v1602818108/Prebuilt%20Cards/dwell_xye2a7.jpg"
              />
              <PrebuiltCard
                onClick={() =>
                  this.props.history.push("/prebuilt?type=wellness")
                }
                background="https://res.cloudinary.com/dropcommerce/image/upload/v1602818109/Prebuilt%20Cards/well-card_bvdhqo.jpg"
              />
            </DashboardCard>
          )}
        {this.props.isFetching > 0 && (
          <React.Fragment>
            <DashboardCard.Skeleton />
            <DashboardCard.Skeleton />
          </React.Fragment>
        )}
        {showAllProducts && favouriteSuppliers && (
          <SuppliersCard
            store={storeID}
            title="Your Favourite Suppliers"
            suppliers={favouriteSuppliers}
            viewAll={() => this.props.history.push("/favorite-suppliers")}
            onClick={(supplierId) =>
              this.props.history.push(`/supplier-id/${supplierId}`)
            }
          />
        )}

        {showAllProducts && featuredSuppliers && (
          <SuppliersCard
            store={storeID}
            title="Featured Suppliers"
            suppliers={featuredSuppliers}
            viewAll={() => this.props.history.push("/suppliers")}
            onClick={(supplierId) =>
              this.props.history.push(`/supplier-id/${supplierId}`)
            }
          />
        )}

        {showAllProducts && newSuppliers && (
          <SuppliersCard
            store={storeID}
            title="New Suppliers"
            suppliers={newSuppliers}
            viewAll={() => this.props.history.push("/suppliers")}
            onClick={(supplierId) =>
              this.props.history.push(`/supplier-id/${supplierId}`)
            }
          />
        )}
        {categories &&
          categories.map((category, i) => (
            <ProductsCard
              key={i}
              title={`${category.name}`}
              products={
                products && products.filter((p) => p.category === category.key)
              }
              viewAll={() =>
                this.props.history.push(`/product/?category=${category.key}`)
              }
              onClick={(productId) =>
                this.props.history.push(`/product/${productId}`)
              }
              showApprovalRequirements={this.props.showApprovalRequirements}
            />
          ))}
      </PageWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
