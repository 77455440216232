import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { Row, Card } from "../../../components";
import { truncateText } from "../../../modules/Format";
import { SkeletonLoader, ButtonNew } from "../../../components";
import ProductCardInside from "../../../views/products/components/ProductCardInside";
import canadaIcon from "../../../images/canadian-flag.png";
import USIcon from "../../../images/american-flag.png";
import Stars from "../../../components/Stars";
import { FiX } from "react-icons/fi";
import Cookies from "js-cookie";

const CardLinkSkeleton = styled(SkeletonLoader)`
  width: 50px;
  height: 25px;
`;

const CardTitleSkeleton = styled(SkeletonLoader)`
  width: 300px;
  height: 25px;
`;

const CardLink = styled.div`
  font-size: 16px;
  color: ${theme.colors.main};
  font-weight: 400;
  border: 1px solid ${theme.colors.lightBorder};
  padding: 12px;
  border-radius: 4px;
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.ultraLightGrey};
  }

  @media (max-width: 800px) {
    margin-left: 0px;
    margin-right: 15px;
    margin-top: 5px;
    padding: 8px;
    font-size: 14px;
  }
`;

const CardTitle = styled.div`
  font-size: 20px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

const CardDescription = styled.div`
  font-size: 18px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;

  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

const StyledCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 25px;
  border: 1px solid ${theme.colors.lightBorder};
  width: 100%;

  @media (max-width: 800px) {
    ${(p) => p.hideMobile && "display: none;"}
  }
`;

const ProductsRow = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 15px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  ${(p) =>
    p.productCount &&
    p.productCount < 4 &&
    "grid-template-columns: 300px 300px 300px;"}
`;

const VideosRow = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 15px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  @media (max-width: 960px) {
    grid-template-columns: 100%;
  }
`;

const Skeleton = () => (
  <StyledCard>
    <Row style={{ justifyContent: "space-between", marginBottom: "20px" }}>
      <CardTitleSkeleton width="300px" />
      <CardLinkSkeleton width="50px" />
    </Row>
    <ProductsRow>
      <Card.Skeleton />
      <Card.Skeleton />
      <Card.Skeleton />
      <Card.Skeleton />
    </ProductsRow>
  </StyledCard>
);

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const StyledTextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid ${theme.colors.lightBorder};
  height: 125px;
  max-width: 800px;
  margin-top: 5px;
  resize: none;

  @media (max-width: 800px) {
    height: 100px;
  }
`;

export class SuppliersCard extends Component {
  state = { expanded: false };

  render() {
    const { suppliers, title, onClick, viewAll } = this.props;

    return (
      <StyledCard>
        <CardRow>
          <CardTitle>{title}</CardTitle>

          <Row>
            <CardLink
              onClick={() => this.setState({ expanded: !this.state.expanded })}
            >
              {this.state.expanded ? "VIEW LESS" : "VIEW 8 MORE +"}
            </CardLink>

            <CardLink onClick={viewAll}>VIEW ALL</CardLink>
          </Row>
        </CardRow>

        <ProductsRow>
          {suppliers &&
            suppliers
              .filter(
                (_, i) => this.state.expanded || (!this.state.expanded && i < 4)
              )
              .filter((supplier) => supplier.name) // removes the ones with no name
              .map((supplier) => (
                <Card
                  store={this.props.store}
                  supplierID={supplier.id}
                  favorite={supplier.favourite}
                  key={supplier.id}
                  image={supplier.header_image}
                  name={supplier.name}
                  subtext={`${supplier.city}, ${supplier.country}`}
                  icon={supplier.country === "Canada" ? canadaIcon : USIcon}
                  onClick={() => onClick(supplier.id)}
                />
              ))}
        </ProductsRow>
      </StyledCard>
    );
  }
}

export class ReviewCard extends Component {
  state = { rating: 0, status: null, review: "" };

  createReview = () => {
    this.props.createStoreReview({
      rating: this.state.rating,
      review: this.state.review,
    });
  };

  render() {
    let appStoreLink = "",
      platformName = "";

    switch (this.props.platform) {
      case "SHOPIFY":
        platformName = "Shopify";
        appStoreLink =
          "https://apps.shopify.com/dropcommerce#modal-show=ReviewListingModal";
        break;

      case "BIGCOMMERCE":
        platformName = "BigCommerce";
        appStoreLink =
          "https://www.bigcommerce.com/apps/dropcommerce-us-dropshipping/#marketplace-appDetail-reviews";
        break;

      case "WIX":
        platformName = "Wix";
        appStoreLink =
          "https://www.wix.com/app-market/add-review/3045b42e-ce82-4360-811e-d7819ba0e75c";
        break;

      case "SHOPLAZZA":
        platformName = "Shoplazza";
        appStoreLink =
          "https://appstore.shoplazza.com/listing/65839/dropcommerce-us-dropshipping/?keyword=dropcommerce&page=1&order_by=best_match";
        break;

      default:
    }

    if (this.state.status === "complete") {
      return (
        <StyledCard>
          <CardTitle style={{ marginBottom: "10px" }}>
            Thank you so much for your feedback!
          </CardTitle>
        </StyledCard>
      );
    }

    if (this.state.status === "feedback") {
      return (
        <StyledCard>
          <CardTitle style={{ marginBottom: "10px" }}>
            Please provide your feedback
          </CardTitle>

          <CardDescription>
            Our team will review each piece of feedback personally and use it to
            improve our system.
          </CardDescription>

          <StyledTextArea
            placeholder={"Type your feedback here..."}
            onChange={(e) => this.setState({ review: e.target.value })}
          />

          <ButtonNew
            text="SUBMIT FEEDBACK"
            width="200px"
            margin="10px 0 0 0"
            onClick={() => {
              this.setState({ status: "complete" });
              this.createReview();
            }}
          />
        </StyledCard>
      );
    }

    if (this.state.status === "appStore") {
      return (
        <StyledCard>
          <CardTitle style={{ marginBottom: "10px" }}>
            {`Last Step: Review Us On ${platformName}`}
          </CardTitle>

          <CardDescription>
            {`We're happy you're happy! Please help us by leaving a review on
            ${platformName}. We would really appreciate it.`}
          </CardDescription>

          <ButtonNew
            text={`REVIEW ON ${platformName.toLocaleUpperCase("en-US")}`}
            width="200px"
            margin="10px 0 0 0"
            onClick={() => {
              window.open(appStoreLink);
            }}
          />
        </StyledCard>
      );
    }

    return (
      <StyledCard>
        <CardTitle style={{ marginBottom: "10px" }}>
          How would you rate DropCommerce?
        </CardTitle>

        <Stars
          type="write"
          updateRating={(rating) => this.setState({ rating })}
          rating={this.state.rating}
        />

        <ButtonNew
          disabled={!this.state.rating}
          text="SUBMIT RATING"
          width="200px"
          margin="10px 0 0 0"
          onClick={() => {
            Cookies.set("reviewSaved", true, { expires: 60 });
            if (this.state.rating === 5) {
              this.createReview();
              this.setState({ status: "appStore" });
            } else {
              this.setState({ status: "feedback" });
            }
          }}
        />
      </StyledCard>
    );
  }
}

const PrebuiltCardBackground = styled.div`
  height: 230px;
  background-image: url(${(p) => p.background});
  background-size: cover;
  background-position: 0% 0%;
  border-radius: 4px;

  @media screen and (max-width: 1500px) {
    height: 180px;
  }

  @media screen and (max-width: 1280px) {
    height: 200px;
  }
`;

export class PrebuiltCard extends Component {
  render() {
    return (
      <PrebuiltCardBackground
        background={this.props.background}
        onClick={this.props.onClick}
      />
    );
  }
}

export class ProductsCard extends Component {
  state = { expanded: false };

  render() {
    const { products, title, onClick, viewAll } = this.props;

    return (
      <StyledCard>
        <CardRow>
          <CardTitle>{title}</CardTitle>

          <Row>
          {/*  <CardLink*/}
          {/*    onClick={() => this.setState({ expanded: !this.state.expanded })}*/}
          {/*  >*/}
          {/*    {this.state.expanded ? "VIEW LESS" : "VIEW 8 MORE +"}*/}
          {/*  </CardLink>*/}

            <CardLink onClick={viewAll}>VIEW ALL</CardLink>
          </Row>
        </CardRow>

        <ProductsRow>
          {products &&
            products
              .filter(
                (_, i) => this.state.expanded || (!this.state.expanded && i < 4)
              )
              .map((product) => (
                <Card
                  key={product.id}
                  image={product.image}
                  name={truncateText(product.title, 20)}
                  subtext={product.supplier_name}
                  description={`$${product.price.toFixed(2)}`}
                  descriptionColor="orange"
                  onClick={() => onClick(product.id)}
                >
                  <ProductCardInside
                    showApprovalRequirements={
                      this.props.showApprovalRequirements
                    }
                    product={product}
                    image={product.image}
                  />
                </Card>
              ))}
        </ProductsRow>
      </StyledCard>
    );
  }
}

class DashboardCard extends Component {
  state = { expanded: false };

  render() {
    const { title, children, hideMobile, hide } = this.props;

    return (
      <StyledCard hideMobile={hideMobile}>
        <Row
          align
          style={{ justifyContent: "space-between", marginBottom: "20px" }}
        >
          <CardTitle>{title}</CardTitle>

          <FiX
            size="24px"
            color={theme.colors.medDarkGrey}
            onClick={hide}
            style={{ cursor: "pointer" }}
          />
        </Row>

        <VideosRow>{children}</VideosRow>
      </StyledCard>
    );
  }
}

DashboardCard.Skeleton = Skeleton;

export default withRouter(DashboardCard);
