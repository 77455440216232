import { CategoriesNew, Pagination, Search } from "../../components";
import { ProductsRow, Row } from "../../components/Layout";
import SortingDropdown from "../../views/products/components/SortingDropdown";
import React, { useEffect, useState } from "react";
import {
  getActiveFilters,
  getUrlParameter,
  listToCommaString,
  objectToQueryString,
  truncateText,
} from "../../modules/Format";
import {
  getIsFetching as getIsFetchingSettings,
  getSettings,
} from "../../Selectors";
import Card from "../../components/Card";
import { Container } from "../../components/Container";
import MDSpinner from "react-md-spinner";
import MoreFilters from "../../components/MoreFilters";
import NoResults from "../../components/NoResults";
import ProductCardInside from "../../views/products/components/ProductCardInside";
import Range from "../../components/Range";
import TabNavigationLight from "../../components/TabNavigationLight";
import Text from "../../modules/Text";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import styled from "styled-components";
import { theme } from "../../modules/Theme";

import { fetchProducts } from "./ProductsActions";
import {
  getError,
  getIsFetchingProducts,
  getPagination,
  getProducts,
} from "./ProductsSelectors";

const mapStateToProps = (state) => ({
  isFetchingSettings: getIsFetchingSettings(state),
  products: getProducts(state),
  settings: getSettings(state),
  pagination: getPagination(state),
  isFetchingProducts: getIsFetchingProducts(state),
  error: getError(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  ...bindActionCreators({ fetchProducts }, dispatch),
});

const defaultProps = {
  isFetchingSettings: false,
  products: [],
  settings: {},
  pagination: {},
  category: "",
  search: "",
};

const ranges = [
  { name: "Price", type: "price" },
  { name: "Profit", type: "profit" },
  { name: "Shipping Rate", type: "shipping" },
];

const Products = (props) => {
  const products = get(props, "products");
  const pagination = get(props, "pagination");

  const [showFiltersTrigger] = useState(window.screen.width < 500);
  const [showFilters, setShowFilters] = useState(window.screen.width >= 500);

  const [filters, setFilters] = useState(initialFilters);

  const resetRange = (type) => {
    let newData = { min: "", max: "", show: false };

    if (type === "profit") {
      newData = { min: "", show: false };
    }

    if (type === "shipping") {
      newData = { max: "", show: false };
    }

    setFilters((currentFilters) => ({
      ...currentFilters,
      [type]: {
        ...currentFilters[type],
        ...newData,
      },
    }));
  };

  const showHideFilter = (type) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      sort: {
        ...currentFilters.sort,
        show: type === "sort" ? !currentFilters.sort.show : false,
      },
      price: {
        ...currentFilters.price,
        show: type === "price" ? !currentFilters.price.show : false,
      },
      profit: {
        ...currentFilters.profit,
        show: type === "profit" ? !currentFilters.profit.show : false,
      },
      shipping: {
        ...currentFilters.shipping,
        show: type === "shipping" ? !currentFilters.shipping.show : false,
      },
      moreFilters: {
        ...currentFilters.moreFilters,
        show: type === "moreFilters" ? !currentFilters.moreFilters.show : false,
      },
    }));
  };

  const updateFilters = (type, value, field = null, refetch = true) => {
    setFilters((currentFilters) => {
      let filters = {
        ...currentFilters,
        [type]: field
          ? {
              ...currentFilters[type],
              [field]: value,
            }
          : value,
      };

      if (type === "category") {
        filters = {
          ...filters,
          page: 1,
          search: "",
          subcategory: "",
        };
      }

      if (type === "subcategory") {
        filters = {
          ...filters,
          page: 1,
          search: "",
        };
      }

      if (refetch) {
        fetchProducts(true, filters);
      }

      return filters;
    });
  };

  const updateMoreFilters = (filterLabel, value, maxOneSelected = false) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      moreFilters: {
        ...currentFilters.moreFilters,
        filters: currentFilters.moreFilters.filters.map((filter) => {
          if (filter.label === filterLabel) {
            return {
              ...filter,
              options: filter.options.map((option) => {
                if (option.value === value) {
                  return {
                    ...option,
                    selected: !option.selected,
                  };
                } else {
                  if (maxOneSelected) {
                    return { ...option, selected: false };
                  }
                }
                return option;
              }),
            };
          }
          return filter;
        }),
      },
    }));
  };

  const resetMoreFilters = () => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      moreFilters: {
        ...currentFilters.moreFilters,
        filters: currentFilters.moreFilters.filters.map((filter) => {
          return {
            ...filter,
            options: filter.options.map((option) => {
              return {
                ...option,
                selected: false,
              };
            }),
          };
        }),
      },
    }));
  };

  const initializeFilters = (location) => {
    let page = getUrlParameter("page", location) || 1;
    let category = getUrlParameter("category", location) || "";
    let subcategory = getUrlParameter("subcategory", location) || "";
    let search = getUrlParameter("search", location) || "";
    let filters_param = getUrlParameter("filters", location) || "";
    let price_min = getUrlParameter("price_min", location) || "";
    let price_max = getUrlParameter("price_max", location) || "";
    let price_type = getUrlParameter("price_type", location) || "cost";
    let profit_min = getUrlParameter("profit_min", location) || "";
    let shipping_max = getUrlParameter("shipping_max", location) || "";
    let shipping_country =
      getUrlParameter("shipping_country", location) || "US";
    let sorting = getUrlParameter("sorting", location) || "default";

    setFilters((currentFilters) => ({
      ...currentFilters,
      page,
      category,
      subcategory,
      search,
      sorting,
      price: {
        ...currentFilters.price,
        min: price_min,
        max: price_max,
        optionsValue: price_type,
      },
      profit: {
        ...currentFilters.profit,
        min: profit_min,
      },
      shipping: {
        ...currentFilters.shipping,
        max: shipping_max,
        optionsValue: shipping_country,
      },
      moreFilters: {
        filters: currentFilters.moreFilters.filters.map((filter) => {
          return {
            ...filter,
            options: filter.options.map((option) => {
              return {
                ...option,
                selected: filters_param.split(",").includes(option.value),
              };
            }),
          };
        }),
      },
    }));
  };

  useEffect(() => {
    if (!props.isFetchingSettings) {
      initializeFilters(props.location);
      fetchProducts(false);
    }
  }, [props.isFetchingSettings]);

  const fetchProducts = (updateUrl = true, updatedFilters = null) => {
    let f = updatedFilters || filters;
    const data = {
      category: f.category,
      subcategory: f.subcategory,
      page: f.page,
      search: f.search,
      sorting: f.sorting,
      product_type: f.product_type,
      filters: listToCommaString(getActiveFilters(f.moreFilters)),
      price_min: f.price.min,
      price_max: f.price.max,
      price_type: f.price.optionsValue,
      profit_min: f.profit.min,
      shipping_max: f.shipping.max,
      shipping_country: f.shipping.optionsValue,
    };

    const queryString = objectToQueryString(data);

    // async
    props.fetchProducts(queryString);

    if (updateUrl) {
      props.history.push(`/products/${queryString}`);
    }
  };

  const { isFetchingSettings, location } = props;
  const search = getUrlParameter("search", location) || "";
  const privatePortal = get(props, ["settings", "store", "private_portal"]);
  const hasDCFulfillment = get(props, ["settings", "has_dc_fulfillment"]);

  if (!isFetchingSettings)
    return (
      <Container>
        <Subwrapper>
          {!hasDCFulfillment ? (
            <Text.Large extra="margin-bottom: 20px;">Products</Text.Large>
          ) : (
            <TabNavigationLight
              tabs={[
                {
                  name: "All Products",
                  link: () => {
                    updateFilters("product_type", "all");
                  },
                  active: filters.product_type === "all",
                },
                {
                  name: "Dropshipping",
                  link: () => {
                    updateFilters("product_type", "dropshipping");
                  },
                  active: filters.product_type === "dropshipping",
                },
              ]}
            />
          )}

          <FilterSearchRow>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Search
                maxWidth="34vw"
                onChange={(term) => updateFilters("search", term, null, false)}
                onEnterPress={(e) => e.key === "Enter" && fetchProducts()}
                search={filters.search}
                clear={() => {
                  updateFilters("search", "");
                }}
                initialSearchTerm={search}
                searchForProducts={fetchProducts}
              />

              {showFiltersTrigger && (
                <Text.Small
                  style={{
                    alignSelf: "center",
                    cursor: "pointer",
                    marginLeft: "16px",
                  }}
                  onClick={() => setShowFilters(!showFilters)}
                >
                  Filters
                </Text.Small>
              )}
            </div>

            {showFilters && (
              <FiltersRow>
                <SortingDropdown
                  items={filters.sort.options}
                  updateFilters={updateFilters}
                  showHideFilter={showHideFilter}
                  data={filters.sort}
                />

                {ranges.map((range) => (
                  <Range
                    range={range}
                    key={range.type}
                    submit={fetchProducts}
                    updateFilters={updateFilters}
                    showHideFilter={showHideFilter}
                    resetRange={resetRange}
                    data={filters[range.type]}
                  />
                ))}

                <MoreFilters
                  submit={fetchProducts}
                  showHideFilter={showHideFilter}
                  updateMoreFilters={updateMoreFilters}
                  resetMoreFilters={resetMoreFilters}
                  moreFilters={filters.moreFilters}
                />
              </FiltersRow>
            )}
          </FilterSearchRow>
        </Subwrapper>

        <CategoriesProductsRow>
          {!privatePortal && (
            <CategoriesNew
              category={filters.category}
              subcategory={filters.subcategory}
              clearCategory={() => {
                updateFilters("category", "");
              }}
              setCategory={(category, type) => {
                updateFilters(type, category);
              }}
            />
          )}

          <div style={{ width: "100%" }}>
            {props.isFetchingProducts || isFetchingSettings > 0 ? (
              <ProductsRow>
                <Card.Skeleton />
                <Card.Skeleton />
                <Card.Skeleton />
                <Card.Skeleton />
                <Card.Skeleton />
                <Card.Skeleton />
                <Card.Skeleton />
                <Card.Skeleton />
              </ProductsRow>
            ) : (
              <Row style={{ width: "100%", height: "100%" }}>
                {products.length > 0 ? (
                  <div style={{ width: "100%", marginBottom: "40px" }}>
                    <ProductsRow>
                      {products.map((product, i) => {
                        if (!product.quantity) return null;

                        return (
                          <Card
                            key={i}
                            image={product.thumbnail_image_url}
                            name={truncateText(product.title, 25)}
                            subtext={product.supplier.name}
                            description={`$${product.price.toFixed(2)}`}
                            descriptionColor="orange"
                            onClick={() =>
                              props.history.push(`/product/${product.id}`)
                            }
                          >
                            <ProductCardInside
                              showApprovalRequirements={
                                props.showApprovalRequirements
                              }
                              product={product}
                              image={product.thumbnail_image_url}
                            />
                          </Card>
                        );
                      })}
                    </ProductsRow>

                    {get(pagination, "first_item") && (
                      <Pagination
                        onClick={(page) => {
                          updateFilters("page", page);
                        }}
                        pagination={pagination}
                      />
                    )}
                  </div>
                ) : props.error ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {props.error}
                  </div>
                ) : (
                  <NoResults
                    title="No products fit this category or search term"
                    message="Please contact support@dropcommerce.com for support"
                    buttonLink={() => {
                      setFilters(initialFilters);
                      fetchProducts();
                    }}
                    buttonText="CLEAR FILTERS"
                  />
                )}
              </Row>
            )}
          </div>
        </CategoriesProductsRow>
      </Container>
    );
  else return <MDSpinner />;
};

Products.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Products);

export const MarginWrapper = styled.div`
  margin-right: 20px;
  margin-left: 20px;

  @media screen and (max-width: 800px) {
    margin-right: 0px;
    margin-left: 0px;
  }
`;

export const CategoriesProductsRow = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Subwrapper = styled.div``;

export const MobileLoading = styled.div`
  display: none;

  @media screen and (max-width: 800px) {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const CategoryTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  color: ${theme.colors.medDarkGrey};
`;

export const CategoryLink = styled.div`
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: ${theme.colors.main};
  margin-left: 20px;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.mainDark};
  }
`;

export const CategoryHeaderRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const TagsContainer = styled.div`
  margin-top: 20px;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const FilterSearchRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const FiltersRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media screen and (max-width: 800px) {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: row-reverse;
    justify-content: center;
    max-width: 95vw;
    flex-wrap: wrap;
  }
`;

export const CloseIcon = styled.img`
  height: 18px;
  width: 18px;
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const initialFilters = {
  page: 1,
  search: "",
  sorting: "default",
  category: "",
  subcategory: "",
  product_type: "all",
  moreFilters: {
    show: false,
    filters: [
      {
        label: "Supplier Country",
        maxOneSelected: true,
        options: [
          { value: "country_us", label: "United States", selected: false },
          { value: "country_canada", label: "Canada", selected: false },
        ],
      },
      {
        label: "Ships To",
        options: [
          { value: "ships_to_us", label: "United States", selected: false },
          { value: "ships_to_canada", label: "Canada", selected: false },
          { value: "ships_intl", label: "All Countries", selected: false },
        ],
      },
      {
        label: "Manufacturing Country",
        maxOneSelected: true,
        options: [
          {
            value: "made_in_us",
            label: "Made In US",
            selected: false,
          },
          {
            value: "made_in_canada",
            label: "Made In Canada",
            selected: false,
          },
        ],
      },
      {
        label: "Values",
        options: [
          {
            value: "eco_friendly",
            label: "Eco Friendly",
            selected: false,
          },
          {
            value: "organic",
            label: "Organic",
            selected: false,
          },
          {
            value: "handmade",
            label: "Handmade",
            selected: false,
          },
          {
            value: "fair_trade",
            label: "Fair Trade",
            selected: false,
          },
          {
            value: "kosher",
            label: "Kosher",
            selected: false,
          },
          {
            value: "women_owned",
            label: "Women Owned Business",
            selected: false,
          },
          {
            value: "vegan",
            label: "Vegan",
            selected: false,
          },
          {
            value: "small_batch",
            label: "Small Batch",
            selected: false,
          },
          {
            value: "social_good",
            label: "Social Good",
            selected: false,
          },
        ],
      },
      {
        label: "Other",
        options: [
          {
            value: "my_suppliers",
            label: "My Suppliers",
            selected: false,
          },
          {
            value: "no_approval_required",
            label: "No Approval Required",
            selected: false,
          },
          {
            value: "branded_invoicing",
            label: "Branded Invoicing",
            selected: false,
          },
          {
            value: "hide_imported",
            label: "Hide Imported Products",
            selected: false,
          },
          {
            value: "show_all_products",
            label: "Show all products",
            selected: false,
          }
        ],
      },
    ],
  },
  price: {
    show_dollar_symbol: true,
    min: "",
    max: "",
    show: false,
    description: "*This filter uses the average variant price for each product",
    optionsField: "price_type",
    optionsValue: "cost",
    optionsType: "radio",
    options: [
      {
        label: "Price",
        value: "cost",
        hint: "The discounted price you will pay the supplier to fulfill an order.",
      },
      {
        label: "Retail Price",
        value: "retail_price",
        hint: "The supplier's Retail Price that they normally sell to customers for.",
      },
    ],
  },
  profit: {
    show_dollar_symbol: true,
    min: "",
    show: false,
    description:
      "*Estimated profit if sold at retail price, based on average variant price",
  },
  shipping: {
    show_dollar_symbol: true,
    max: "",
    show: false,
    optionsField: "shipping_country",
    optionsValue: "US",
    optionsType: "radio",
    options: [
      {
        label: "United States",
        value: "US",
        hint: "Find products with a US shipping rate below your max value, or suppliers who have at least one product that fits this criteria.",
      },
    ],
  },
  sort: {
    show: false,
    options: [
      { key: "default", label: "Default" },
      { key: "new", label: "New" },
      { key: "sold", label: "Most Sold" },
      { key: "imported", label: "Most Imported" },
    ],
  },
};
