import { isEmpty } from "lodash";
import React, { useState } from "react";
import MDSpinner from "react-md-spinner";
import { ButtonNew, Input } from "../../../../components";
import { Show } from "../../../../components/Show";
import { useEffectOnce } from "../../../../hooks";
import { errorNotification, successNotification, } from "../../../../modules/Notification";
import { theme } from "../../../../modules/Theme";
import { axiosInstance } from "../../../../services/api";
import { validEmail } from "../../../../validation";
import { SettingsPageWrapper, SettingsSubWrapper } from "../../SettingsStyle";
import SettingsItem from "../../SettingsItem";
import { Checkbox } from "@material-ui/core";
import Label from "../../../../components/Label";

export function StoreProfile() {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffectOnce(() => {
    axiosInstance
      .get("/store_info/")
      .then((res) => {
        const { data } = res;
        setData({
          name: data.name,
          email: data.owner_email,
          country: data.country,
          plan: data.plan,
          show_all_products: data.show_all_products,
          hide_all_button: data.hide_all_button,
          created: new Date(data.created_datetime).toDateString()
        });
      })
      .catch((err) => {
        setError("Error fetching settings");
      })
      .finally(() => {
        setIsFetching(false);
      });
  });

  const getAccountDescription = () => {
    let message = `This store from ${data.country} was created on ${data.created}`
    if (data.plan) {
      const planName = data.plan.split("|")[0]
      message += ` and it has an active ${planName} plan.`
    } else {
      message += ` and it has no active.`
    }
    return message;

  };

  const handleChange = (e) => {
    setData((currentState) => ({
      ...currentState,
      [e.target.name]: e.target.value,
    }));
  };
    const handleCheckbox = (e) => {
      setData((currentState) => ({
      ...currentState, "show_all_products": e.target.checked,
      }));
    }

  const isValid = () => {
    const errors = {};
    if (data.name === "" || data.name.length < 3) {
      errors.name = "Please enter a valid name";
    }
    if (!validEmail(data.email)) {
      errors.email = "Please enter a valid email";
    }
    setErrors(errors);
    return isEmpty(errors);
  };

  const handleSubmit = (e) => {
    // abort
    if (!isValid()) return;

    setIsSaving(true);

    const formData = new FormData();

    formData.set("name", data.name);
    formData.set("owner_email", data.email);
    formData.set("show_all_products", data.show_all_products);

    axiosInstance
      .put("/store_info/", formData)
      .then((res) => {
        successNotification("Saved successfully");
      })
      .catch((err) => {
        errorNotification("Failed saving data");
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <SettingsPageWrapper>
      <Show
        when={!isFetching}
        fallback={<MDSpinner singleColor={theme.colors.main} size={40} />}
      >
        {error && <div>{error}</div>}
        {!isEmpty(data) && (
          <div>
            <SettingsSubWrapper style={{ gap: "20px" }}>
              <Input
                label="Name"
                name="name"
                value={data.name}
                onChange={handleChange}
                error={errors.name}
              />
            </SettingsSubWrapper>
            <SettingsSubWrapper>
              <Input
                label="Email"
                name="email"
                value={data.email}
                onChange={handleChange}
                error={errors.email}
              />
            </SettingsSubWrapper>
            <SettingsSubWrapper>
              <Label light>Show All Products
                <Checkbox
                  color="primary"
                  onChange={handleCheckbox}
                  checked={data.show_all_products}
                />
              </Label>
            </SettingsSubWrapper>
            <SettingsSubWrapper>
              <ButtonNew
                text="Save Changes"
                onClick={handleSubmit}
                isFetching={isSaving}
                extra="margin-top: 10px;"
              />
            </SettingsSubWrapper>
            <br />
            <SettingsSubWrapper>
              <SettingsItem
                title="Account Details"
                description={getAccountDescription()}
              />
            </SettingsSubWrapper>
          </div>
        )}
      </Show>
    </SettingsPageWrapper>
  );
}
